import React, { Component, Fragment } from "react";
import remark from 'remark';
// import recommended from 'remark-preset-lint-recommended';
import remarkHtml from 'remark-html';
import ContextConsumer from "../../Context";
import "./index.scss";
import Button from "../Button";
import IconArrowLeftWhite from "../../img/icon-arrow-left-white.svg";
import IconArrowRightWhite from "../../img/icon-arrow-right-white.svg";

export default class OrderedList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mobileShownItemIndex: 0,
    };
    
    this.setMobileShownItemIndex = this.setMobileShownItemIndex.bind(this);
  }
  
  setMobileShownItemIndex(newValue) {
    this.setState({ mobileShownItemIndex: newValue });
  }

  render() {
    const { content, list } = this.props;
    const { mobileShownItemIndex } = this.state;

    const parser = remark()
      // .use(recommended)
      .use(remarkHtml);

    const data = list.map(({content}) => {
      return parser.processSync(content).toString()
    });

    const mobileItemsStyle = {
      transform: `translateX(-${mobileShownItemIndex / 3 * 100}%)`,
  };

    return (
      <ContextConsumer>
        {({ mobileMode }) => (
          <div className="ol">
            <div className="ol__items" style={mobileMode ? mobileItemsStyle : {}}>
              {data.map((textContent, index) => (
                <div className="ol__item" key={index}>
                  <div className="ol__item_index">
                    <span>{index + 1}</span>
                  </div>

                  <div className="ol__item_text" dangerouslySetInnerHTML={{__html: textContent}}/>
                </div>
                )
              )}
            </div>

            <div className="ol__buttons">
              <div className="ol__button_wrapper">
                <Button
                  shape="flat"
                  content={<img src={IconArrowLeftWhite} alt="" className="ol__button_icon" />}
                  disabled={mobileShownItemIndex === 0}
                  onClick={() => {
                    if (mobileShownItemIndex > 0) {
                      this.setMobileShownItemIndex(mobileShownItemIndex - 1);
                    }
                  }}
                />
              </div>

              <div className="ol__button_wrapper">
                <Button
                  shape="flat"
                  content={<img src={IconArrowRightWhite} alt="" className="ol__button_icon" />}
                  disabled={mobileShownItemIndex === data.length - 1}
                  onClick={() => {
                    if (mobileShownItemIndex < data.length - 1) {
                      this.setMobileShownItemIndex(mobileShownItemIndex + 1);
                    }
                  }}
                />
              </div>
            </div>

            {!mobileMode ? <div className="ol__video">
              <div className="ol__video_frame">
                <div className="ol__video_wrapper">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/mWKhEhSIHYA?modestbranding=1&rel=0&showinfo=0" frameBorder="0"
                          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen></iframe>
                </div>
              </div>
            </div> : null}

            <div className="ol__comment">

              <div className="ol__comment_text" dangerouslySetInnerHTML={{__html: content}}/>

            </div>
          </div>
        )}
      </ContextConsumer>
    );
  }
}
