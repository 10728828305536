import React, { Component, Fragment } from "react";
import ContextConsumer from "../../Context";
import "./index.scss";
import Button from "../Button";
import {graphql, StaticQuery} from 'gatsby';
import remark from "remark";
// import recommended from 'remark-preset-lint-recommended';
import remarkHtml from 'remark-html';
import cx from "classnames";
import IconArrowLeftWhite from "../../img/icon-arrow-left-white.svg";
import IconArrowRightWhite from "../../img/icon-arrow-right-white.svg";

class UnorderedList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mobileShownItemIndex: 0,
    };
    
    this.setMobileShownItemIndex = this.setMobileShownItemIndex.bind(this);
  }
  
  setMobileShownItemIndex(newValue) {
    this.setState({ mobileShownItemIndex: newValue });
  }

  render() {
    const { list } = this.props;
    const { mobileShownItemIndex } = this.state;
    const mobileItemsStyle = {
      transform: `translateX(-${mobileShownItemIndex / 4 * 100}%)`,
    };

    const parser = remark()
      // .use(recommended)
      .use(remarkHtml);

    const data = list.map(({content}) => {
      return parser.processSync(content).toString()
    });

    return (
      <ContextConsumer>
        {({ mobileMode }) => (
          <div className="ul">
            <div className="ul__bg" />

            <div className="ul__items_wrapper">
              <div className="ul__items" style={mobileMode ? mobileItemsStyle : {}}>
                {data.map((content, index) => (
                  <div
                    key={index}
                    className={cx("ul__item", {"ul__item--color":  index === 0})}
                    dangerouslySetInnerHTML={{__html: content}}
                  />
                ))}
              </div>
            </div>

            <div className="ul__buttons">
              <div className="ul__button_wrapper">
                <Button
                  shape="flat"
                  content={<img src={IconArrowLeftWhite} alt="" className="ul__button_icon" />}
                  disabled={mobileShownItemIndex === 0}
                  onClick={() => {
                    if (mobileShownItemIndex > 0) {
                      this.setMobileShownItemIndex(mobileShownItemIndex - 1);
                    }
                  }}
                />
              </div>

              <div className="ul__button_wrapper">
                <Button
                  shape="flat"
                  content={<img src={IconArrowRightWhite} alt="" className="ul__button_icon" />}
                  disabled={mobileShownItemIndex === 3}
                  onClick={() => {
                    if (mobileShownItemIndex < 3) {
                      this.setMobileShownItemIndex(mobileShownItemIndex + 1);
                    }
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </ContextConsumer>
    );
  }
}

export default () => (
  <StaticQuery
    query={graphql`
      query {
        benefits: markdownRemark(fields: {name: {eq: "index"}, post: {eq: "benefits"}}) {
          frontmatter {
            list {
              content
            }
          }
        }
      }
    `}
    render={(data) => (
      <UnorderedList list={data.benefits.frontmatter.list}/>
    )}
  />
);
