import React, { Component, Fragment } from "react";
import { graphql } from "gatsby";
import IndexContent from "../components/IndexPageContent";
import Helmet from "react-helmet";
import Config from '../../data/SiteConfig';

export default class IndexPage extends Component {
  render() {
    return (
      <Fragment>
        <Helmet title={[this.props.data.markdownRemark.frontmatter.title, Config.siteTitle].join(' | ')} />

        <IndexContent />
      </Fragment>
    );
  }
}

export const pageQuery = graphql`
  query HomePage {
    markdownRemark(fields:{slug: {eq: "/"}}) {
      html

      frontmatter {
        title
      }

      fields {
        slug
        post
      }
    }
  }
`;
