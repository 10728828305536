import React, { Component, Fragment } from "react";
import ContextConsumer from "../../Context";
import "./index.scss";
import UnorderedList from "../UnorderedList";
import {graphql, StaticQuery} from 'gatsby';

class UnorderedListSection extends Component {
  render() {
    const { benefits } = this.props;

    return (
      <ContextConsumer>
        {({ toggleModal }) => (
          <section className="s">
            <div className="s__content">
              <div className="s__title">
                <h2 className="s__title_title s__title_title--right s__title_title--color">
                  {benefits.frontmatter.title}
                </h2>
                <div className="s__title_underline s__title_underline--right" />
              </div>

              <UnorderedList />
            </div>
          </section>
        )}
      </ContextConsumer>
    );
  }
}

export default () => (
  <StaticQuery
    query={graphql`
      query {
        benefits: markdownRemark(fields: {name: {eq: "index"}, post: {eq: "benefits"}}) {
          frontmatter {
            title
          }
          html
        }
      }
    `}
    render={(data) => (
      <UnorderedListSection benefits={data.benefits}/>
    )}
  />
);
