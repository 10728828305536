import React, { Component, Fragment } from "react";
import ContextConsumer from "../../Context";
import "./index.scss";
import Button from "../Button";
import PhotoWithFlag from "../../img/photo-with-flag.jpg";
import PhotoWithFlagFade from "../../img/photo-with-flag-fade.png";
import Flag from "../../img/flag.png";
import OrderedList from "../OrderedList";
import UnorderedListSection from "../UnorderedListSection";
import { StaticQuery, graphql } from "gatsby"

class IndexContent extends Component {
  render() {
    const {header, about, how, docs} = this.props;

    return (
      <ContextConsumer>
        {({ toggleModal, mobileMode }) => (
          <Fragment>
            <section id="sFlag" className="s s--bg_begin">
              <div className="s__content s_flag__content">
                {mobileMode ?
                  <div className="s_flag__video">
                    <div className="s_flag__video_wrapper">
                      <iframe width="560" height="315" src="https://www.youtube.com/embed/mWKhEhSIHYA?modestbranding=1&rel=0&showinfo=0" frameBorder="0"
                              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                              allowFullScreen/>
                    </div>
                  </div>
                  :
                  <div className="s_flag__img">
                    <img className="s_flag__img_photo" src={PhotoWithFlag} alt="" />

                    <img className="s_flag__img_fade" src={PhotoWithFlagFade} alt="" />

                    <img className="s_flag__img_flag" src={Flag} alt="" />
                  </div>}

                <div className="s_flag__box_wrapper">
                  <div className="s_flag__title">
                    <h2 className="s_flag__title_title">{header.frontmatter.title}</h2>

                    <div className="s_flag__title_subtitle" dangerouslySetInnerHTML={{__html: header.html}} />

                    <div className="s_flag__title_underline" />
                  </div>
                </div>

                <div className="s__txt s__txt--flag" dangerouslySetInnerHTML={{__html: header.html}}/>

                <div className="s_flag__box_wrapper">
                  <div className="s_flag__btn_wrapper">
                    <Button
                      to="/order"
                      shape="raised"
                      content="Ansök nu"
                      sublabel="Börja här"
                      withArrow
                      flagSection
                      onClick={() => toggleModal()}
                    />
                  </div>
                </div>

              </div>
            </section>

            <section className="s s--bg">
              <div className="s__content">
                <div className="s__title">
                  <h2 className="s__title_title">{about.frontmatter.title}</h2>
                  <div className="s__title_underline" />
                </div>

                <div className="s__txt" dangerouslySetInnerHTML={{__html: about.html}}/>
              </div>
            </section>

            <section className="s s--bg_end">
              <div className="s__content">
                <div className="s__title">
                  <h2 className="s__title_title s__title_title--right">{how.frontmatter.title}</h2>
                  <div className="s__title_underline s__title_underline--right" />
                </div>

                <OrderedList content={how.html} list={how.frontmatter.list} />
              </div>
            </section>

            <section className="s">
              <div className="s__content s_woman__content">
                <div className="s_woman__text_wrapper">
                  <div className="s__title">
                    <h2 className="s__title_title">{docs.frontmatter.title}</h2>
                    <div className="s__title_underline" />
                  </div>

                  <div className="s_woman__text" dangerouslySetInnerHTML={{__html: docs.html}}/>
                </div>

                <div className="s_woman__img" />
              </div>
            </section>

            <UnorderedListSection />
          </Fragment>
        )}
      </ContextConsumer>
    );
  }
}

export default () => (
  <StaticQuery
    query={graphql`
      query {
        header: markdownRemark(fields: {name: {eq: "header"}, post: {eq: "home"}}) {
          frontmatter {
            title
          }
          html
        }
        how: markdownRemark(fields: {name: {eq: "how"}, post: {eq: "home"}}) {
          frontmatter {
            title
            list {
              content
            }
          }
          html
        }
        about: markdownRemark(fields: {name: {eq: "about"}, post: {eq: "home"}}) {
          frontmatter {
            title
          }
          html
        }
        docs: markdownRemark(fields: {name: {eq: "docs"}, post: {eq: "home"}}) {
          frontmatter {
            title
          }
          html
        }
      }
    `}
    render={(data) => (
      <IndexContent about={data.about} how={data.how} header={data.header} docs={data.docs}/>
    )}
  />
);
